<template>
  <b-card>
    <div>
      <div class="text-black text-2xl font-semibold">
        Data Event
      </div>
    </div>
    <b-row>
      <b-col
        class="d-flex items-center justify-content-end"
        style="align-items: flex-start;"
      >
        <div>
          <BInputGroup class="wrapper-search">
            <template #prepend>
              <BInputGroupText>
                <b-img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
                />
              </BInputGroupText>
            </template>
            <BFormInput
              v-model="state.search"
              type="search"
              placeholder="Cari Judul Event"
              @input="debounceSearch"
            />
          </BInputGroup>
        </div>
        <div class="ml-1">
          <div class="text-center">

            <button
              class="btn-primary rounded-lg mb-[-3px] "
              @click="state.filter = !state.filter"
            >
              <img
                class="w-11 p-[5px]"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
                alt=""
              >
            </button>
            <ul
              v-if="state.filter"
              class="d-block position-absolute bg-[#f9f9f9] z-10 text-black font-medium text-[12px]"
            >
              <div class="text-black text-start pl-2 py-1 text-[16px] border">
                Filter
              </div>

              <div class="hover:bg-[#FCD4BE]">
                <button
                  id="popOverFilter"
                  variant="outline-primary"
                  class="btn btn1 rounded-none mb-1 d-flex"
                >
                  <span>Type Event</span>

                </button>
                <b-popover
                  target="popOverFilter"
                  triggers="focus"
                  placement="left"
                >
                  <b-form-checkbox-group v-model="filterSelected">
                    <div
                      v-for="(item, index) in state.listFilter"
                      :key="index"
                    >
                      <b-form-checkbox
                        :id="'filter_' + index"
                        :value="item.value"
                        class="mt-1"
                        @change="handleFilter(item)"
                      >
                        <div class="text-black">
                          {{ item.text }}
                        </div>

                      </b-form-checkbox>
                    </div>
                  </b-form-checkbox-group>
                </b-popover>
              </div>
              <div class="hover:bg-[#FCD4BE]">
                <button
                  id="popOverFilterStatus"
                  variant="outline-primary"
                  class="btn btn-status rounded-none mb-1 d-flex"
                >
                  <span>Status</span>

                </button>
                <b-popover
                  target="popOverFilterStatus"
                  triggers="focus"
                  placement="left"
                >
                  <b-form-checkbox-group v-model="filterStatusSelected">
                    <div
                      v-for="(item, index) in state.listFilterStatus"
                      :key="index"
                    >
                      <b-form-checkbox
                        :id="'filter_' + index"
                        :value="item.value"
                        class="mt-1"
                        @change="handleFilter(item)"
                      >
                        <div class="text-black">
                          {{ item.text }}
                        </div>

                      </b-form-checkbox>
                    </div>
                  </b-form-checkbox-group>
                </b-popover>
              </div>
              <div class="hover:bg-[#FCD4BE] text-primary hover:text-white">
                <button
                  class="btn btn2 font-semibold mb-1 d-flex"
                  @click="handleResetFilter"
                >
                  <span class=" btn-txt ">Reset Filter</span>

                </button>
              </div>
            </ul>

          </div>
        </div>
        <div class="ml-1">
          <b-button
            variant="primary"
            class="p-[0.8rem] text-white"
            @click="handleAddEvent"
          >
            Tambah Event
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <BOverlay
          id="infinite-list"
          variant="light"
          :show="state.loading"
          spinner-variant="primary"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            :items="state.items"
            :fields="state.fields"
            hover
            show-empty
            empty-text="Belum ada event yang aktif"
            responsive
            class="mt-1"
            @row-clicked="handleViewEvent"
          >
            <template #cell(no)="data">
              <div style="min-width: 10px !important">
                {{ data.index + 1 }}
              </div>
            </template>
            <template #cell(title_event)="data">
              <div
                class="d-flex items-center gap-5"
                style="min-width: 20px !important; "
              >
                <div><b-img
                  :src="data.item.poster"
                  fluid
                  style="width: 120px; height: 74px; object-fit: cover;"
                /></div>
                <div
                  style=" max-width: 200px !important; display: grid;"
                >
                  <span>{{ data.item.event_title }} :</span>
                  <span>
                    {{ truncateText(data.item.event_sub_title, 30) }}
                  </span>
                </div>
              </div>
            </template>
            <template #cell(type_event)="data">
              <div style="min-width: 20px !important">
                {{ data.item.event_type }}
              </div>
            </template>
            <template #cell(status_event)="data">
              <div style="min-width: 20px !important;">
                <b-badge
                  v-if="data.item.status === 'active'"
                  pill
                  style="background-color: #BEFCDE ; color:#34A770 ; width: -webkit-fill-available;"
                  class="bg-[#BEFCDE] text-[#34A770]"
                >
                  Aktif
                </b-badge>
                <b-badge
                  v-else
                  pill
                  style="background-color: #FCBEBE ; color:#E31A1A ; width: -webkit-fill-available;"
                  class="bg-[#FCBEBE] text-[#E31A1A]"
                >
                  Tidak Aktif
                </b-badge>
              </div>

            </template>
            <template #cell(total_participant)="data">
              <div style="min-width: 20px !important">
                {{ data.item.total_attendance }} / {{ data.item.max_attendance }}
              </div>

            </template>
            <template #cell(time)="data">
              <div style="min-width: 20px !important">
                {{ DAY_MONTH_YEAR(data.item.event_start_date) }}
              </div>
            </template>
            <template #cell(action)="data">
              <div
                class="d-flex"
                style="gap: 1rem; min-width: 20px !important "
              >
                <div
                  style="cursor: pointer;"
                  @click="handleEditEvent(data.item.id)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                    alt="logo-edit"
                    srcset=""
                    class="w-full"
                  >
                </div>
                <div
                  v-b-modal.modal-delete-event
                  style="cursor: pointer;"
                  @click="handleDelete(data.item.id)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                    alt="logo-eye"
                    class="w-full"
                  >
                </div>
              </div>
            </template>
          </BTable>
        </BOverlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-center">
        <div
          v-if="state.resultSearch"
          class="text-[13px]"
        >
          Ditemukan {{ state.items.length }} data <span v-if="state.dataSearch">"{{ state.search }}"</span> <span v-if="state.datafilter">"Type event: {{ filterSelected.toString() }}"</span>
        </div>
      </b-col>
    </b-row>
    <div>
      <modal-delete-event
        @submit="handleDeleteEvent"
      />
    </div>
  </b-card>

</template>
<script>
import {
  reactive, onMounted, computed, ref,
} from '@vue/composition-api'
import router from '@/router'
import { useRouter } from '@/@core/utils/utils'
import { newAxiosIns } from '@/libs/axios'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import {
  columnsTableEvent, truncateText, toastSuccess, toastError,
} from './config'

import modalDeleteEvent from './ModalDeleteEvent.vue'

export default {
  components: {
    modalDeleteEvent,
  },
  setup() {
    const filter = ref([])
    const filterStatus = ref([])
    const filterSelected = ref([])
    const filterStatusSelected = ref([])
    const route = useRouter()
    const state = reactive({
      id: '',
      limit: 25,
      offset: 0,
      lastData: false,
      search: '',
      datafilter: false,
      dataSearch: false,
      loading: false,
      resultSearch: false,
      fields: columnsTableEvent,
      items: [],
      filter: false,
      selectedFilter: [],
      debounceSearch: null,
      listFilter: [
        { text: 'Bootcamp', value: 'bootcamp', checked: false },
        { text: 'Gathering', value: 'gathering', checked: false },
        { text: 'Kopdar Komunitas', value: 'kopdar komunitas', checked: false },
        { text: 'Webinar', value: 'webinar', checked: false },
      ],
      listFilterStatus: [
        { text: 'Aktif', value: 'upcoming', checked: false },
        { text: 'Tidak Aktif', value: 'done', checked: false },
      ],
    })

    async function getListData() {
      state.offset = 0
      state.loading = true
      const params = `search=${state.search}&type=${filterSelected.value.join(',')}&limits=${state.limit}&offsets=${state.offset}&time=${filterStatusSelected.value.join('')}`
      const url = `/komclass/api/v1/event?${params}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          state.items = data

          if (data.length < state.limit) {
            state.lastData = true
          } else {
            state.lastData = false
          }

          state.offset = data.length
          state.loading = false
          state.resultSearch = false
        })

        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err)
          state.loading = false
        })
    }

    function nextData() {
      if (!state.lastData) {
        state.loading = true
        const params = `search=${state.search}&type=${filterSelected.value.join(',')}&limits=${state.limit}&offset=${state.offset}&time=${filterStatusSelected.value.join(',')}`
        const url = `/komclass/api/v1/event?${params}`
        newAxiosIns.get(url)
          .then(res => {
            const { data } = res.data
            state.items.push(...data)
            state.offset += data.length

            if (data.length < state.limit) {
              state.lastData = true
            }
            state.loading = false
          })
          .catch(err => {
          // eslint-disable-next-line no-console
            console.error(err)
            state.loading = false
          })
      }
    }

    function handleEmptySearch() {
      if (state.search === '') {
        state.resultSearch = false
      }
    }

    async function handleSearch(value) {
      if (!value.length) {
        state.search = ''
        getListData()
      } else if (value.length > 2) {
        state.loading = true
        state.search = value
        getListData()
        state.loading = false
      }
    }
    function debounceSearch(search) {
      if (!search) {
        state.resultSearch = false
      }
      if (state.debounceSearch) {
        clearTimeout(state.debounceSearch)
      }
      state.debounceSearch = setTimeout(() => {
        handleSearch(search)
      }, 1000)
    }

    function handleAddEvent(idEvent) {
      router.push({
        name: 'data-event-add',
      })
    }

    function handleViewEvent(item) {
      const idEvent = item.id
      router.push({
        name: 'data-event-detail',
        query: { id: idEvent },
      })
    }

    function handleEditEvent(id) {
      const idEvent = id
      router.push({
        name: 'data-event-edit',
        query: { id: idEvent },
      })
    }

    function handleResetFilter() {
      filterSelected.value = []
      filter.value = []
      filterStatusSelected.value = []
      getListData()
    }

    function handleFilter() {
      state.loading = true
      if (!filterSelected.value.length || !filterStatusSelected.value.length) {
        filter.value = []
        filterStatus.value = []
        getListData()
        return
      }
      filter.value = filterSelected.value.join(',')
      filterStatus.value = filterStatusSelected.value.join(',')
      getListData()
      state.loading = false
    }
    function infinite() {
      const listElm = document.getElementById('infinite-list')
      listElm.addEventListener('scroll', e => {
        if (
          Math.ceil(listElm.scrollTop + listElm.clientHeight)
          >= listElm.scrollHeight
        && !state.loading
        ) {
          nextData()
        }
      })
    }

    const handleDelete = id => {
      state.id = id
    }

    const handleDeleteEvent = () => {
      state.loading = true
      const url = `/komclass/api/v1/event/${state.id}`
      newAxiosIns.delete(url)
        .then(res => {
          if (res.data.meta.code === 201) {
            toastSuccess('Data Berhasil Dihapus')
            setTimeout(window.location.reload(), 3000)
          }
        })
      state.loading = false
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err)
          toastError('Gagal Menghapus Data')
          state.loading = false
        })
    }

    computed(() => {
      state.filter = false
      state.resultSearch = false
      state.dataSearch = false
      state.datafilter = false
    })

    onMounted(() => {
      getListData()
      infinite()
      handleEmptySearch()
    })

    return {
      getListData,
      route,
      filter,
      DAY_MONTH_YEAR,
      router,
      state,
      filterSelected,
      handleFilter,
      handleEditEvent,
      handleViewEvent,
      handleSearch,
      handleAddEvent,
      debounceSearch,
      handleResetFilter,
      filterStatusSelected,
      truncateText,
      handleDeleteEvent,
      handleDelete,
      filterStatus,
    }
  },
}
</script>
<!-- <style scoped lang="scss">
@import '../style/event.scss';
</style> -->

<style>
.wrapper-search .form-control {
  border: 1px solid #e2e2e2;
  border-left: 0 !important;
  border-radius: 8px;
}
.wrapper-search .input-group-prepend .input-group-text {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.btn1:hover {
background-color: #F95031;
color: white;
border-radius: 0;
}

.btn-txt {
  color: #F95031;
  font-weight: 600;
}

.btn2:hover {
background-color: white;
border-radius: 0;
}

.btn-status:hover {
  width: 100%;
  background-color: #F95031;
color: white;
border-radius: 0;
}
#infinite-list {
  height: 100vh;
  overflow-y: scroll;
}
/* .btn-txt:hover {
color: white;
} */

</style>
